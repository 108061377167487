.subscriptionModal {
  @apply py-4 px-2;
  width: 410px;
}

.appleSection {
  @apply mt-4;
}

.appleTitle, .androidTitle {
  @apply flex justify-between;
}

.appleTitle h5, .androidTitle h5 {
  @apply font-bold;
}

.appleBody, .androidBody {
  @apply pl-2 pt-2 text-gray-700;
}

.boundary {
  @apply w-full my-4;
  border-bottom: 1px solid #E0E0E0;
}

@media (min-width: 768px) {
  .subscriptionModal {
    width: 460px;
  }
}