.container {
  @apply flex flex-col flex-grow md:px-10 xl:px-32 xs:px-0;
}

.main {
  @apply pt-6 lg:pt-24 pb-0 lg:pb-20 flex flex-col;
}

.footer {
  width: 100%;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  color: #424242;
  @apply pb-8 lg:pb-10 px-2 text-sm sm:text-base sm:px-11;
}

.footer a:hover {
  color: #c50e11;
}

.footer > svg.icon:hover {
  stroke: #c50e11;
}

.footerTop {
  @apply flex flex-col border-b border-lightgray lg:flex-row lg:justify-between lg:pb-4;
}

.footerNavLogo {
  @apply flex flex-col lg:flex-row;
}

.footerLogo {
  @apply pb-4 lg:pb-0;
}

.footerNav {
  @apply flex flex-wrap justify-start lg:pl-8;
}

.footerNav li a {
  @apply font-normal inline-block align-top py-2 pr-5 relative;
  letter-spacing: 0.5px;
  font-family: 'Lab regular';
  color: #424242;
}

.footerNav * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.footerSocial {
  @apply flex justify-start py-6 xs:pl-0 xl:pl-32 lg:py-0;
}

.footerSocial span {
  @apply pl-4 flex whitespace-nowrap items-center;
}

.footerSocial span a {
  @apply flex items-center;
}

.footerSocial span:first-of-type {
  @apply pl-0;
}

.footerBottom {
  @apply flex flex-col justify-start py-4 lg:justify-end pb-3 lg:pb-0 xs:pr-0 sm:pr-0 sm:pl-0 md:pr-0 lg:flex-row lg:justify-between;
}

.footerBottom div a {
  @apply pl-3 flex items-center flex-shrink-0;
}

.footerBottom div a:first-of-type {
  @apply pl-0 lg:pl-3;
}

.footerCopyright {
  @apply flex items-center;
}

.footerLinks {
  @apply flex flex-row pt-3 lg:pt-0;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: left;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 45%;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (hover: hover) {
  .slackButton:hover {
    @apply bg-slackPurple text-white shadow-sm;
  }

  .appButton:hover {
    @apply bg-black text-white shadow-sm;
  }

  .appButton:hover .hoverShow {
    @apply block;
  }

  .appButton:hover .hoverHide {
    @apply hidden;
  }
}

.buttons {
  flex-wrap: wrap;
}

.messageFooterSocial {
  @apply flex justify-start;
}

.messageFooterSocial span {
  @apply pl-4 flex whitespace-nowrap items-center;
}

.messageFooterSocial span a {
  @apply flex items-center;
}

.messageFooterSocial span:first-of-type {
  @apply pl-0;
}

/* TODO: Uncomment the following when the SoleSavy app is not running */
/* .appButton {
  display: none;
} */
