.circle {
  background-color: #c50e11;
  @apply relative w-10 h-10 rounded-full flex items-center justify-center;
}

.greeting {
  background-color: #c50e11;
  @apply relative w-20 h-20 rounded-full flex items-center justify-center;
}

.MenuWrapper {
  min-width: 218px;
  border-radius: 6px;
  @apply p-6 bg-white shadow;
}

.DropdownCenter {
  position: absolute;
  top: 5.8rem;
  right: -3.65rem;
}

.DropdownRight {
  position: absolute;
  top: 4rem;
  right: -1rem;
}

.MenuArrow {
  top: -35px;
  right: 22%;
  @apply absolute block w-10 h-10 bg-white rounded-md transform rotate-45;
}

.DropdownRight .MenuArrow {
  right: 1rem;
}

.MenuWrapper ul {
  @apply flex flex-col;
}

.MenuWrapper ul li {
  padding: 8px 0 8px 12px;
  display: flex;
  align-items: center;
}

.MenuWrapper ul li a {
  padding: 0;
}

.MenuWrapper ul li:first-of-type {
  padding: 0 0 8px 12px;
}

.MenuWrapper ul li:last-of-type {
  padding: 8px 0 0 12px;
}

.MenuWrapper ul li a {
  color: #424242;
}

.MenuWrapper ul li a:hover {
  color: #151515;
}

.MenuWrapper ul li a:hover:after {
  transform: none;
  display: none;
}
