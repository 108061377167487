.settingsContainer {
  font-family: 'Lab regular';
  width: 343px;
  @apply grid grid-flow-row w-full bg-white h-auto p-8 gap-6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.03)) drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.02));
}

@media (min-width: 1024px) {
  .settingsContainer {
    width: 695px;
    @apply ml-16;
  }
}

/* TODO: This should probably be within a global CSS file */
.settingsContainer h3 {
  font-family: 'Tommy bold';
  font-size: 28px;
  @apply pb-2;
}

.settingsContainer h4 {
  font-family: 'Lab regular';
  @apply text-lg;
}

.settingsContainer input:disabled {
  @apply text-gray-600;
}

.markImage {
  @apply pr-2 min-w-fit;
  padding-top: 2px;
}

.settingsInput {
  height: 47px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
}

/* TODO: It appears we need to import another tommy bold for weight = 700 */
.settingsContainer button {
  justify-self: end;
}

.field {
  @apply w-full relative;
}

.field > div {
  height: auto;
}

.field input {
  @apply h-11 text-gray-750 focus:border rounded;
}

.field label {
  @apply text-gray-550 focus:border focus:text-gray-750;
}

.field span:not([type]) {
  @apply text-errorPure;
}

.field label:before,
.field label:after {
  border-top-width: 1px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.field label:before {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
}

.field label:after {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 4px !important;
}

.absoluteSpan span {
  @apply absolute;
}

.settingsInput:read-only {
  color: #616161;
  background-color: white;
  cursor: not-allowed;
  pointer-events: none;
}

.accountForm {
  @apply w-full lg:w-min;
}

.formSection {
  @apply flex flex-col relative w-full gap-6 lg:justify-between justify-start;
}

.nameSection {
  @apply flex flex-grow gap-3;
}

.birthdaySection {
  @apply relative;
}

.socialSection {
  @apply relative;
}

.optional {
  @apply absolute right-3 top-4 font-bold tracking-wider bg-white;
  font-size: 10px;
  color: #bdbdbd !important;
}

.settingsDiv {
  @apply flex flex-col flex-grow;
}

.settingsMain {
  @apply flex flex-col pb-20 pt-8 justify-center z-0 md:pl-12 md:pr-12 lg:flex-row;
}

.newPass span {
  margin-top: 18px;
}

.passGuide {
  top: -8px;
  font-size: 14px;
}

@media only screen and (max-width: 430px) {
  .newPass span,
  .passGuide {
    max-width: 155px;
  }
}

/* accomodate smaller screens */
@media only screen and (max-width: 390px) {
  .newPass span,
  .passGuide {
    max-width: 140px;
  }
}

.confirmPassAlert {
  text-align: right;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 144%;
}

.goodPass,
.scoreWordClass {
  color: #10b981;
}

.badPass {
  color: #c50e11;
}

.dropdownSection {
  @apply flex flex-col flex-grow gap-6;
}

.registerNavbar {
  height: 88px;
  @apply px-4 md:px-12;
}

.registerContent {
  @apply h-registerHeight flex-col lg:flex-row justify-evenly md:justify-center;
}

@media (max-height: 850px) {
  .registerContent {
    height: fit-content;
  }
}

.registerSideColumn {
  flex-basis: 28%;
  @apply h-full bg-contain bg-no-repeat hidden lg:block;
}

.verCharacter {
  @apply ml-0 rounded;
}

.maleFemale {
  @apply flex gap-4;
}

.selfDescribe {
  @apply flex;
}

.choiceField {
  @apply flex justify-center border border-lightgray rounded items-center grow hover:cursor-pointer;
  transition: all 0.2s ease;
}

.choiceField input[type='radio'] {
  @apply border border-lightgray;
}

.choiceField input {
  @apply hidden;
}

.choiceSelected {
  @apply ring-2 ring-black ring-inset;
}

.choiceField input[type='checkbox'] {
  @apply h-16 opacity-75;
}

.choiceField img {
  @apply opacity-50;
}

.choiceField.choiceSelected img {
  @apply opacity-100;
}

.choiceRadio {
  @apply border border-lightgray h-12;
}

.choiceCheckbox {
  height: 68px;
}

.dividerLine {
  height: 1px;
  @apply grow border-b border-lightgray;
}

.overLineText {
  @apply font-sfProTextBold uppercase text-gray-550 text-xxs text-center tracking-xl font-bold;
}

.brandsWrapper {
  transition: all 1s ease;
  max-height: 30px;
  overflow: hidden;
}

.brandsLoaded {
  max-height: 500px;
}

.message > div {
  align-items: flex-start;
}

.message a {
  @apply underline;
}

/* 

.checkBoxWrapper input[type='checkbox'] {
  @apply hidden;
}

.checkBoxWrapper input[type='checkbox'] + div {
  display: inline-block;
  position: relative;
  top: -1px;
  min-width: 12px;
  min-height: 12px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #ccc;
  cursor: pointer;
}

.checkBoxWrapper input[type='checkbox']:checked + div {
  background: #c50e11 -19px top no-repeat;
}

.checkBoxWrapper input[type='checkbox'] + div {
  margin-right: 4px;
}

@apply inline-block relative cursor-pointer grow min-w-checkBox min-h-checkBox border border-lightgray rounded; */
