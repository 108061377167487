.container {
  @apply w-full flex relative z-20 items-center justify-between py-4 md:py-0;
}

.headerLinks {
  @apply hidden md:block;
}

.headerLinks li {
  @apply inline-block;
}

.headerLinks li a {
  @apply text-lg font-normal inline-block align-top py-9 px-4 relative;
  letter-spacing: 0.5px;
  font-family: 'Lab regular';
  color: #424242;
  font-size: 1.125rem;
}

.headerLinks li a:hover {
  color: #151515;
}

.headerLinks li a:after {
  content: '';
  position: absolute;
  height: 2px;
  bottom: 34px;
  background: #c50e11;
  left: 14px;
  right: 14px;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.2s ease;
}

.headerLinks li a:hover:after {
  transform: scaleY(1);
}

.headerLinks li a.headerLinkActive,
a.headerLinkActive {
  color: #151515;
}

.headerLinks li a.headerLinkActive:after,
a.headerLinkActive:after {
  content: '';
  position: absolute;
  height: 2px;
  bottom: 34px;
  background: #c50e11;
  left: 14px;
  right: 14px;
  transform-origin: top;
  transition: all 0.2s ease;
  transform: scaleY(1);
}

.headerLinks * {
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 767px) {
  .hamburgerMenu a.headerLinkActive {
    width: fit-content;
    color: #151515;
  }

  .hamburgerMenu a.headerLinkActive:after {
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}

@media (max-width: 1024px) {
  .headerLinks li a {
    font-size: 1rem;
  }
}

.logo {
  @apply text-lg font-normal inline-block align-top py-9  relative;
  left: 50%;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  transform: translate(-50%);
}
@media (max-width: 768px) {
  .leftLogo {
    width: 75%;
    padding-left: 0.5rem;
  }
}

.hamburgerIcon {
  @apply md:hidden flex flex-col gap-1 cursor-pointer focus:outline-none;
}

.hamburgerMenu {
  @apply flex-col gap-6 absolute left-0 right-0 h-screen w-full bg-gray-500 z-10 md:hidden font-labRegular;
}

.hamburgerMenu a {
  @apply text-lg font-normal py-3 relative;
  letter-spacing: 0.5px;
  font-family: 'Lab regular';
  color: #424242;
}
