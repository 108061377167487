.description {
  @apply text-gray-700 mt-3 text-base;
  margin-bottom: 1.4rem;
}

.rightCard {
  @apply md:px-14 py-10 md:py-0;
  min-width: -webkit-fill-available;
}

.bodyIcon {
  margin-top: 2px;
}

.upgradePlanModal {
  @apply flex flex-col justify-center text-center mt-6;
  width: 31rem;
}

.warningBody {
  @apply border rounded-md p-3 mt-5 flex;
  border-color: #FEF3C7;
  background-color: #FEF3C7;
}

.markImage {
  @apply pr-2 min-w-fit;
  padding-top: 2px;
}

.warningText {
  color: #78350F;
  text-align: left;
}

@media (max-width: 768px) {
  .card {
    min-height: 240px;
  }

  .upgradePlanModal {
    width: 26rem;
  }
}

@media (min-width: 768px) {
  .card {
    min-height: 444px;
  }

  .description {
    @apply text-lg;
  }
}
