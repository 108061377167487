.title {
  @apply text-center font-tommyBlack text-xxxl text-gray-750 tracking-sm lg:text-xxxxl lg:tracking-sm lg:leading-10;
}

@media only screen and (max-width: 1024px) {
  .title {
    width: 400px;
  }
}

.planCardGroup {
  @apply flex flex-col lg:flex-row gap-0.5 items-center lg:items-baseline pb-6;
}

.planCardGroup .cardWrapper:last-of-type {
  @apply lg:rounded-tr-lg lg:rounded-br-lg rounded-b-lg;
}

.planItem {
  @apply flex gap-3;
}

.planItem span:first-of-type {
  @apply shrink-0;
}

.cardWrapper {
  width: 400px;
  min-height: 452px;
  @apply font-sfProTextRegular py-10 px-8 bg-white relative grow-0 shadow-light;
}

.tagSpacing {
  @apply relative py-20 lg:rounded-lg rounded-t-lg;
}

.tagExtraPadding {
  @apply pt-0;
}

.tag {
  @apply font-sfProTextBold uppercase text-xs md:text-xxs absolute left-1/2 -translate-x-1/2 bg-chipInactive py-1 px-2 text-red-700 rounded-full -top-3;
}

.currencyRate {
  @apply flex gap-1 pb-8;
}

.currencyRate p {
  @apply font-sfProTextBold text-xxs md:text-xs text-gray-600 uppercase;
}

.disclaimer {
  @apply text-gray-600 pb-14;
}

@media only screen and (max-width: 1024px) {
  .disclaimer {
    width: 400px;
  }
}

.periodOption {
  @apply uppercase font-sfProTextBold tracking-widest text-xs z-10 hover:text-successPure;
}

.selected {
  @apply text-white hover:text-white;
}

.toggleWrapper {
  width: 384px;
  height: 52px;
  @apply flex items-center relative rounded-full bg-white shadow-light px-4 py-2 justify-around;
}

.toggleSelected {
  width: 172px;
  height: 36px;
  @apply absolute left-5 bg-successPure rounded-full z-0 ease-in duration-100;
}
