.backdropFilter {
  @apply backdrop-filter backdrop-blur flex flex-col justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none;
  background-color: rgba(0, 0, 0, 0.56);
  transition: opacity 350ms;
  opacity: 0;
}

.modalContentWrapper {
  @apply relative w-auto my-6 mx-auto max-w-full;
  transition: margin-top 350ms;
  margin-top: 48px;
}

.fullScreenModalContent {
  padding-top: 108px;
  @apply relative w-auto my-6 mx-auto max-w-full grow;
}

.backdropFilterOpen {
  opacity: 1;
}

.modalContentOpen {
  @apply mt-6 mx-4;
}

.fullScreenModalContentOpen {
  @apply m-0 h-full;
}

.fullScreen {
  @apply w-screen h-full bg-gray-500;
}

.modalContent {
  @apply clear-both border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none;
}

.fullScreenM0 {
  @apply m-0;
}

@media (max-width: 768px) {
  .videoModal {
    height: calc((100vw - 20px) * 0.5775);
    width: calc(100vw - 48px);
  }
}
